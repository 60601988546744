<template>
  <div>
    <v-container 
      fluid
      class="footer"
    >
      <!-- COPYRIGHT INFO -->
      <v-row 
        class="mb-2"
        justify="center"
      >
        <span>&#169; by Synthetic Sympathy</span>
      </v-row>

      <!-- LINKS -->
      <v-row justify="center">
        <v-btn
          v-for="(item) in menuItems"
          :key="item"
          class="hover-glow"
          text
          @click="goTo(item)"
        >
          {{ $t(item) }}
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'FooterBar',
  data: () => {
    return {
      menuItems: [
        'impressum',
        'privacy',
        'disclaimer',
        'contact',
      ]
    }
  },
  methods: {
    /**
     * Navigate to different page and emit event for scrolling to top
     * @param {String} target name of the target page
     */
    goTo(target) {
      if(this.$router.currentRoute.name === target) {
        return;
      }
      this.$emit("scrollToTop");
      this.$router.push({ name: target });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style-variables.scss";

.footer {
  background-color: black;
  position: absolute;
  z-index: 1000;
}

.footer * {
  color: $highlight-color !important;
}
</style>