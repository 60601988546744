<template>
  <v-menu top>
    <!-- MENU -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="language-switcher hover-glow"
        text
        v-bind="attrs"
        v-on="on"
      >
        {{ $i18n.locale }}
      </v-btn>
    </template>

    <!-- MENU ITEMS -->
    <v-list class="language-menu">
      <v-list-item
        class="language-menu__item hover-glow"
        v-for="(item, index) in languages"
        :key="index"
        :disabled="item === $i18n.locale"
        @click="changeLanguage(item)"
      >
        <v-list-item-title class="language-switcher">
          {{ item }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  data: () => {
    return {
      languages: ['en', 'de'],
    };
  },
  methods: {
    /**
     * Changes the current language
     * @param {String} language language to which will be switches
     */
    changeLanguage(language) {
      this.$i18n.locale = language;
      this.$router.push({
        params: {
          lang: this.$i18n.locale,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style-variables.scss";

.language-switcher {
  color: $highlight-color;
  font-weight: 800;
  font-size: 16px;
}

.language-menu {
  background-color: black !important;

  &__item {
    margin: auto;
    width: 90%;
  }
}
</style>