<template>
  <v-app 
    :style="{
      'background-color': 'black',
      'background-attachment': 'fixed'
     }"
  >
    <v-main>
      <navigation-bar 
        v-if="!lightboxOpen"
        id="navigation-bar" 
        @scrollToTop="scrollToTop"
      />
      <!-- fog effect -->
      <div class="fog-container">
        <div
          :style="{'background-image': `url(${require('./assets/pictures/background/fog_1.png')})`}"
          class="fog-img fog-img-first"
        />
        <div
          :style="{'background-image': `url(${require('./assets/pictures/background/fog_2.png')})`}"
          class="fog-img fog-img-second"
        />
      </div>
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view class="content" />
      </transition>
      <footer-bar v-if="!lightboxOpen" @scrollToTop="scrollToTop"/>
    </v-main>
  </v-app>
</template>

<script>
import footerBar from '@/components/app/footer.component.vue';
import navigationBar from '@/components/app/navigation-bar.component.vue';

export default {
  name: 'App',
  components: {
    footerBar,
    navigationBar
  },
  data: ()=> {
    return {
      showNavigationBar: true,
    }
  },
  computed: {
    lightboxOpen() {
      return this.$store.getters['settings/lightboxOpen'];
    },
  },
  watch: {
    '$route' (to) {
      document.title = this.$t(to.meta.title) + ' | Synthetic Sympathy'
    }
  },
  methods: {
    /**
     * Scroll to the top of the page (navigation-bar)
     */
    scrollToTop() {
      document.getElementById('content').scrollIntoView({behavior: 'smooth'});
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/style-variables.scss";
@import "@/assets/scss/global-styling.scss";

// fade effect for router
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.8s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
