export const utils = {
    methods: {
        /**
         * Checks if website is accessed from a mobile device
         * @return {Boolean} whether site is accessed from a mobile device or not
         */
        isMobile() {
            if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
            ) {
            return true;
            }
            return false;
        },
        }
}