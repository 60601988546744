<template>
  <div>
    <!-- COVER -->
    <v-container 
      fluid
      class="cover"
    >
      <v-row justify="center">
        <img
          class="cover__image"
          src="@/assets/pictures/general/cover-image.jpg"
        />
      </v-row>
    </v-container>

    <!-- NAVIGATION BAR -->
    <v-container
      fluid
      class="navigation-bar"
      :class="{ fixed: !top }"
    >
      <v-row class="navigation-bar__buttons">
        <!-- LOGO -->
        <v-col cols="2">
          <v-row justify="center">
            <v-app-bar-nav-icon>
              <v-img
                contain
                class="navigation-bar__logo"
                src="@/assets/pictures/general/bar-logo.png"
                @click="goTo('home')"
              />
            </v-app-bar-nav-icon>
          </v-row>
        </v-col>

        <!-- NAVIGATION LINKS -->
        <v-col cols="8">
          <v-row 
            justify="center"
            v-if="!isMobile() && !$vuetify.breakpoint.smAndDown"
          >
            <v-btn
              v-for="(item) in menuItems"
              :key="item"
              class="hover-glow"
              text
              @click="goTo(item)"
            >
             {{ $t(item) }}
            </v-btn>
          </v-row>
          <v-row
            justify="center"
            v-else
          >
            <v-btn 
              class="basic-button"
              @click="toggleNavigationMenu"
            >
              <v-icon>{{ icons.mdiMenu }}</v-icon>
            </v-btn>
          </v-row>
        </v-col>

        <!-- LANGUAGE SWITCHER -->
        <v-col cols="2">
          <v-row
            class="mr-3"
            justify="end"
          >
            <language-switcher />
          </v-row>
        </v-col>
      </v-row>
      <div
        v-if="$vuetify.breakpoint.smAndDown"
        class="slider"
        :class="{'opened': navigationMenuOpen}"
      >
        <v-row 
          v-for="item in menuItems"
          :key="item"
          class="mb-3"
          justify="center"
        >
          <v-btn
            class="hover-glow"
            text
            @click="goTo(item)"
          >
            {{ $t(item) }}
          </v-btn>
        </v-row>
      </div>
    </v-container>
    <div
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      class="divAsMargin"
      :class="{'exists': navigationMenuOpen}"
    />
  </div>
</template>

<script>
import languageSwitcher from "@/components/app/language-switcher.component.vue";
import { mdiMenu } from '@mdi/js';
import styleVariables from "@/assets/scss/style-variables.scss";
import { utils } from '@/components/mixins/util-methods.mixin.js';

export default {
  name: 'NavigationBar',
  components: {
    languageSwitcher,
  },
  mixins: [
    utils
  ],
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data: () => {
    return {
      coverImageHeight: '200px',
      icons: {
        mdiMenu
      },
      menuItems: [
        'home',
        'news',
        'discography',
        'band',
        'contact',
      ],
      navigationMenuOpen: false,
      top: true,
    };
  },
  methods: {
    /**
     * Navigate to different page
     * @param {String} target name of the target page
     */
    goTo(target) {
      this.navigationMenuOpen = false;
      if(this.$router.currentRoute.name === target) {
        return
      }
      if(!this.top) {
        this.$emit('scrollToTop');
      }
      this.$router.push({ name: target });
    },
    /**
     * Switches classes of the elements so the navigation bar becomes fixed on top when user scrolled far enough
     */
    handleScroll() {
      if (window.scrollY > styleVariables.coverImageHeight.slice(0, -2)) {
        this.top = false;
      } else {
        this.top = true;
      }
    },
    /**
     * Opens / closes the navigation menu
     */
    toggleNavigationMenu() {
      this.navigationMenuOpen = !this.navigationMenuOpen;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style-variables.scss";

.cover {
  background-color: #0a0e14;
  padding-top: 0;
  position: relative;
  z-index: 1000;
  overflow: hidden;

  &__image {
    height: $cover-image-height;
    box-shadow: 0 0 10px 10px #0a0e14;
  }
}

.divAsMargin {
  height: 0;
  transition-property: height;
  transition-duration: .5s;
}

.divAsMargin.exists {
  height: 200px;            // adjust to number of  menu items: (items-1)*50px 
}

.language-switcher * {
  color: white !important;
}

.navigation-bar {
  background-color: black;
  padding: 0;
  position: absolute;
  top: $cover-image-height;
  z-index: 1000;

  &__logo {
    height: $navigation-bar-logo-height;
    cursor: default;
  }

  & * {
    color: $highlight-color;
    font-weight: 800;
    font-size: 16px;
  }

  &__buttons {
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
}

.navigation-list-item {
  background-color: black;
  color: $highlight-color !important;
  justify-content: center;
}

@media screen and (min-width: 540px) {
    .navigation-bar__buttons {
        max-width: 80%;
    }
}

.fixed {
  position: fixed;
  top: 0;
}

.slider {
	overflow: hidden;
	max-height: 0;
	transition-property: all;
	transition-duration: .5s;
}

.slider.opened {
  padding-top: 7px;
  max-height: 250px;        // adjust to number of  menu items: items*50px
}
</style>