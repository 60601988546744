import Vue from 'vue';
import Vuex from 'vuex';
import settings from '@/store/stores/settings.store.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        settings,
    }
})