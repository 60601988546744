import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index.js';
import vuetify from './plugins/vuetify';
import i18n from './i18n';

Vue.config.productionTip = false

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    to.params.lang = 'de';
    language = 'de';
    next({name: 'home', params: {lang: to.params.lang}})
  }

  // set the current language for vuex-i18n. note that translation data
  // for the language might need to be loaded first
  i18n.locale = language;
  next();

});

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
