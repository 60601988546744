import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: '/:lang',
    component: {
      render (c) {
        return c('router-view')
      }
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: {
          title: 'home',
        }
      },
      {
        path: 'band',
        name: 'band',
        component: () => import('../views/Band.vue'),
        meta: {
          title: 'band',
        }
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('../views/Contact.vue'),
        meta: {
          title: 'contact',
        }
      },
      {
        path: 'discography',
        name: 'discography',
        component: () => import('../views/Discography.vue'),
        meta: {
          title: 'discography',
        }
      },
      {
        path: 'disclaimer',
        name: 'disclaimer',
        component: () => import('../views/Disclaimer.vue'),
        meta: {
          title: 'disclaimer',
        }
      },
      {
        path: 'impressum',
        name: 'impressum',
        component: () => import('../views/Impressum.vue'),
        meta: {
          title: 'impressum',
        }
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('../views/News.vue'),
        meta: {
          title: 'news',
        }
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: () => import('../views/Privacy.vue'),
        meta: {
          title: 'privacy',
        }
      },
      {
        path: '404',
        name: 'notFound',
        component: () => import('../views/NotFound.vue'),
        meta: {
          title: 'notFound',
        }
      },
      {
        path: '*',
        redirect: {name: 'notFound'},
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
