import Vue from 'vue';

export default {
    namespaced: true,
    state() {
        return {
            videosApprovalGiven: false,             // if the user has agreed to the privacy information
            lightboxOpen: false,                    // if a photo is opened in fullscreen
        }
    },
    mutations: {
        set: (state, {key, value}) => {
            Vue.set(state, key, value);
        }
    },
    getters: {
        videosApprovalGiven: (state) => state.videosApprovalGiven,
        lightboxOpen: (state) => state.lightboxOpen,
    }
}